import { FC } from "react";
import { classNames } from "../utilities/helpers";

export const ComboCard: FC<{
  dark: boolean;
  children: JSX.Element;
  id: string;
}> = ({ dark, children, id }) => {
  return (
    <div id={id} className={classNames(dark ? "bg-black" : "bg-alto", "pt-24")}>
      {children}
    </div>
  );
};
