import { Outlet } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";

export const Root = () => {
  return (
    <div className="flex flex-col items-center justify-between bg-black">
      <MainNavigation />
      <Outlet />
    </div>
  );
};
