import { ButtonTypes, MainButton } from "../MainButtons";

import { FC } from "react";
import { urls } from "../../routes/RouteDefinitions";

export const OurClients = () => {
  return (
    <div className="py-24 bg-gray-100 sm:py-32">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="grid items-center grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
          <div className="grid items-center w-full grid-cols-2 mx-auto gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
            <img
              className="object-contain object-left w-full max-h-12"
              src="./ril.svg"
              alt="Resource Intermediaries Limited"
              // width={105}
              height={48}
            />
            <img
              className="object-contain object-left w-full max-h-12"
              src="./alphamorgan.svg"
              alt="Alpha Morgan"
              // width={104}
              height={48}
            />
            <img
              className="object-contain object-left w-full max-h-12"
              src="./hbc.svg"
              alt="Heritage Bank"
              // width={140}
              height={48}
            />
            <img
              className="object-contain object-left w-full max-h-12"
              src="./hiosl.svg"
              alt="Homeland"
              // width={136}
              height={48}
            />
            <img
              className="object-contain object-left w-full max-h-12"
              src="./egbin.svg"
              alt="Egbin"
              // width={158}
              height={48}
            />
            <img
              className="object-contain object-left w-full max-h-12"
              src="./cdl.svg"
              alt="Credit Direct Limited"
              width={147}
              height={48}
            />
          </div>
          <div className="w-full mx-auto lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Building Success. Together
            </h2>
            <p className="mt-6 leading-8 text-gray-600 text-md">
            Our customer philosophy revolves around one simple principle: empowering you every step of the way. 
            We are committed to providing exceptional support, guidance, and resources to ensure that you achieve 
            success in a collaborative way!
            </p>
            <div className="flex items-center mt-8 gap-x-6">
              <MainButton
                label="Contact Us"
                destination={urls.contact}
                type={ButtonTypes.secondary}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
