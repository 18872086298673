import { MainButton } from "../MainButtons";
import { classNames } from "../../utilities/helpers";
import { FC } from "react";
import { urls } from "../../routes/RouteDefinitions";
export const Footer: FC<{ full: boolean }> = ({ full }) => {
  return (
    <footer className="relative" id="contact">
      <div className="py-12 md:py-24 bg-alto">
        <div className="items-center justify-between px-8 mx-auto space-y-6 lg:flex max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-tight uppercase text-primary-900">
              Contact Us
            </h2>
            <div
              className={classNames("bg-gray-900", "w-12 h-0.5 my-2 ")}
            ></div>
            <a
              href="mailto:info@quetzalconsults.com"
              className="py-8 text-xl font-medium tracking-tight text-gray-500 md:text-4xl"
            >
              info
              <span className="font-bold text-gray-900">
                @quetzalconsults.com
              </span>
            </a>
          </div>
          <div className="text-gray-600 text-md">
            <p className="font-bold text-gray-900">24B, Bashorun Okusanya,</p>
            <p className="text-sm font-light">Off Admiralty Way, Lekki</p>
            <p className="text-sm font-light">Lagos. Nigeria</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
