import { FC } from "react";
import { ButtonProps, MainButton } from "../MainButtons";
import { classNames } from "../../utilities/helpers";
export const SectionCardContent: FC<{
  header: string;
  title: string;
  copy?: string;
  buttonProps?: ButtonProps;
  dark: boolean;
}> = ({ header, title, copy, buttonProps, dark }) => {
  return (
    <div className="">
      <div>
        <h2 className="text-base font-semibold tracking-tight uppercase text-primary-900">
          {header}
        </h2>
        <div
          className={classNames(
            dark ? "bg-gray-200" : "bg-gray-900",
            "w-12 h-0.5 mt-2 "
          )}
        ></div>
      </div>

      <p
        className={classNames(
          dark ? "text-gray-100" : "text-gray-900",
          "relative mt-6 text-2xl font-bold leading-8 tracking-tight  sm:text-6xl"
        )}
      >
        {title}
      </p>
      {copy && (
        <p
          className={classNames(
            dark ? "text-gray-400" : "text-gray-700",
            "relative my-6 leading-8  text-md sm:max-w-md lg:max-w-none"
          )}
        >
          {copy}
        </p>
      )}
      {buttonProps && (
        <MainButton
          label={buttonProps.label}
          destination={buttonProps.destination}
          type={buttonProps.type}
        />
      )}
    </div>
  );
};
