import { FC } from "react";
import { classNames } from "../utilities/helpers";

export enum ButtonTypes {
  primary,
  secondary,
}

export interface ButtonProps {
  label: string;
  destination: string;
  type?: ButtonTypes;
}

export const MainButton: FC<ButtonProps> = ({ label, destination, type }) => {
  return (
    <a
      className={classNames(
        type === ButtonTypes.secondary
          ? "bg-black text-gray-25 hover:bg-gray-600 hover:text-gray-100"
          : "text-gray-900 bg-gray-50 hover:text-primary-900 hover:bg-gray-200",
        "px-6 py-4 mt-6 text-sm font-semibold leading-6  rounded-sm "
      )}
      href="mailto:info@quetzalconsults.com?subject=Let's Build Together!"
    >
      {label}
    </a>
  );
};

MainButton.defaultProps = {
  type: ButtonTypes.primary,
};
