import { createBrowserRouter } from "react-router-dom";
import { Root } from "./Root";
import { HomePage } from "../pages/HomePage";
import { ContactUsPage } from "../pages/ContactUs";
import { AboutUsPage } from "../pages/AboutUs";

export const urls = {
  contact: "#contact",
  home: "/",
  aboutUs: "#about-us",
  services: "#services",
};
export const router = createBrowserRouter([
  {
    path: urls.home,
    element: <Root />,
    children: [{ index: true, element: <HomePage /> }],
  },
]);
