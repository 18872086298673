import { FC } from "react";
import { classNames } from "../../utilities/helpers";
import { Disclosure, Transition } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { MainButton } from "../MainButtons";
import { urls } from "../../routes/RouteDefinitions";
export const AccordionHolder: FC<{
  dark: boolean;
  header: string;
  title: string;
  defaultOpen: boolean;
  copy: string;
}> = ({ dark, header, title, defaultOpen, copy }) => {
  return (
    <>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <div className="my-6 md:my-12">
            <div className="flex justify-between gap-6">
              <div>
                <div>
                  <h2 className="text-base font-semibold tracking-tight text-left text-gray-400 uppercase">
                    {header}
                  </h2>
                  <div
                    className={classNames(
                      dark ? "bg-gray-500" : "bg-gray-900",
                      "w-12 h-0.5 mt-2 "
                    )}
                  ></div>
                </div>

                <p
                  className={classNames(
                    dark ? "text-gray-100" : "text-gray-900",
                    "relative mt-6 text-lg font-semibold leading-8 tracking-tight  sm:text-3xl"
                  )}
                >
                  {title}
                </p>
              </div>
              <Disclosure.Button>
                <div
                  className={classNames(
                    open ? "bg-primary-900" : "bg-gray-600",
                    "p-3  rounded-full hover:bg-primary-900 "
                  )}
                >
                  {open ? (
                    <MinusIcon className="w-6 h-6 text-gray-100" />
                  ) : (
                    <PlusIcon className="w-6 h-6 text-gray-100" />
                  )}
                </div>
              </Disclosure.Button>
            </div>

            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel
                static
                className="grid py-8 font-light leading-8 text-gray-400 md:grid-cols-2 text-md"
              >
                <div>
                  <div>{copy}</div>
                  {/* <MainButton label="Learn More" destination="/" /> */}
                </div>
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </>
  );
};
