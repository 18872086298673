import "./App.css";
import MainNavigation from "./components/MainNavigation";
import { HomePage } from "./pages/HomePage";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/RouteDefinitions";

function App() {
  return (
    // <div className="flex flex-col items-center justify-between bg-black">
    //   {/* <header className=""></header> */}
    //   <MainNavigation />
    //   <HomePage />
    // </div>
    <RouterProvider router={router} />
  );
}

export default App;
