import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
export interface Competency {
  header: string;
  title: string;
  copy: string;
  image?: string;
  href: string;
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
}

export const competencies: Competency[] = [
  {
    header: "01",
    title: "Custom and Full Stack Development",
    copy: "Out-of-the box doesn't necessarily give you a head start out-of-the-gate. What you need are tailored solutions that take a holistic approach to solving your business challenges; our experience, reputation for on-time & on-budget delivery will help you overcome those challenges. ",
    image: "",
    href: "#application-development",
    icon: ChartPieIcon,
  },
  {
    header: "02",
    title: "System Integration",
    copy: " With growth comes change. How quickly you adapt to that change determines how much and how fast your business grows. We design service-oriented services to provide integration solution to seamlessly connect your current systems and applications.",
    image: "",
    href: "#system-integration",
    icon: CursorArrowRaysIcon,
  },
  {
    header: "03",
    title: "Mobile Application Development",
    copy: "In today's BYOD business environment, applications and systems that don't leverage the latest mobile technology stifle productivity and growth. At Quetzal Consults we leverage latest technology to deliver a winning mobility strategy for your business",
    image: "",
    href: "#portals-collaboration",
    icon: FingerPrintIcon,
  },
  {
    header: "04",
    title: "Technical Consultation",
    copy: "We work with you to analyze your existing technological investments, discuss your business goals and create a roadmap for technology delivery. Our team will provide actionable insights from refactoring, rebuilding, or a redesign of your technology landscape to scale with your Business’ vision",
    image: "",
    href: "#mobile-application-development",
    icon: SquaresPlusIcon,
  },
];

export const ourValues = [
  {
    name: "Be world-class",
    description:
      "Aut illo quae. Ut et harum ea animi natus. Culpa maiores et sed sint et magnam exercitationem quia. Ullam voluptas nihil vitae dicta molestiae et. Aliquid velit porro vero.",
  },
  {
    name: "Share everything you know",
    description:
      "Mollitia delectus a omnis. Quae velit aliquid. Qui nulla maxime adipisci illo id molestiae. Cumque cum ut minus rerum architecto magnam consequatur. Quia quaerat minima.",
  },
  {
    name: "Always learning",
    description:
      "Aut repellendus et officiis dolor possimus. Deserunt velit quasi sunt fuga error labore quia ipsum. Commodi autem voluptatem nam. Quos voluptatem totam.",
  },
  {
    name: "Be supportive",
    description:
      "Magnam provident veritatis odit. Vitae eligendi repellat non. Eum fugit impedit veritatis ducimus. Non qui aspernatur laudantium modi. Praesentium rerum error deserunt harum.",
  },
  {
    name: "Take responsibility",
    description:
      "Sit minus expedita quam in ullam molestiae dignissimos in harum. Tenetur dolorem iure. Non nesciunt dolorem veniam necessitatibus laboriosam voluptas perspiciatis error.",
  },
  {
    name: "Enjoy downtime",
    description:
      "Ipsa in earum deserunt aut. Quos minus aut animi et soluta. Ipsum dicta ut quia eius. Possimus reprehenderit iste aspernatur ut est velit consequatur distinctio.",
  },
];
