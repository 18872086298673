import { ComboCard } from "../components/ComboCard";
import { MainHero } from "../components/MainHero";
import { TwoColCard } from "../components/TwoColCard";
import { AccordionHolder } from "../components/shared/AccordionHolder";
import { Footer } from "../components/shared/Footer";
import { SectionCardContent } from "../components/shared/SectionCardHeader";
import { competencies } from "../content/HomePage";
export const HomePage = () => {
  return (
    <div className="w-full isolate">
      <MainHero />
      <TwoColCard dark={false} />

      <ComboCard id="services" dark={true}>
        <div className="px-6 pb-24 mx-auto md:px-8 max-w-7xl">
          <SectionCardContent
            title="Our Competencies"
            header="Services"
            dark={true}
          />
          <div className="pt-8">
            {competencies.map((competency) => (
              <AccordionHolder
                key={competency.header}
                dark={true}
                defaultOpen={competency.header === "01"}
                header={competency.header}
                title={competency.title}
                copy={competency.copy}
              />
            ))}
          </div>
        </div>
      </ComboCard>

      {/* <ContactUsForm /> */}
      <Footer full={false} />
    </div>
  );
};
